<template>
  <v-row>
    <v-col cols="12" sm="12" md="11" lg="11">
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>Code</v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6">
              {{ form.code }}
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>Remark</v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6">
              {{ form.remark }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3">
              <v-subheader>Used</v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
              <v-checkbox v-model="form.used" disabled></v-checkbox>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "detail-pph-23-code",
  props: {
    form: Object,
  },
};
</script>

<style></style>
