<template>
  <v-dialog v-model="dialog" max-width="600" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <div class="red--text text-uppercase font-weight-bold">warning !</div>
            <div>
              This action cannot be undone. This will permanently delete the {{ title }}
              <span class="font-weight-bold">{{ invoiceNumber }}</span> and remove all journal if
              exists.
              <br />
              <br />
              <div>
                Please type <span class="font-weight-bold">{{ invoiceNumber }}</span> to confirm.
              </div>
            </div>
          </v-col>
          <v-col cols="12">
            <v-text-field outlined dense flat v-model="validation"></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="close">
          Cancel
        </v-btn>
        <v-btn color="red" @click="deleteItem">
          Delete
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "dialog-delete",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: () => undefined,
    },
    invoiceNumber: {
      type: String,
      default: "",
    },
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      validation: "",
    };
  },
  methods: {
    deleteItem() {
      if (this.validation === this.invoiceNumber) {
        this.$emit("deleteItem", this.id);
      } else {
        return;
      }
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style></style>
